import { RouteComponentProps, useParams } from '@reach/router';
import { Link } from 'gatsby';
import { Button, ButtonGroup, Card, Divider, Icon, List, SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import { useDeleteStoryLine } from '../../mutations';
import { useStoryLineList } from "../../queries";
import { IStoryLine } from "../../story-api";
import Layout from "../layout/Layout";
import PageHeader, { DividerWithToolbar } from '../layout/PageHeader';
import { ConfirmationButton } from '../misc/confirmation';
import PleaseWaitComponent from '../misc/pleaseWaitComponent';
import PleaseWaitPage from "../layout/pleaseWaitPage";
import CollectionImage from "../misc/storyLineImage";
import { NewLineModal } from './NewLineModal';
import { AuthorIcon } from '../misc/authorIcon';
import StorySegment from './StorySegment';

const StoryLinesList = (_props: RouteComponentProps) => {
  const { story: path } = useParams();
  if (!path ||  typeof document === "undefined") return <PleaseWaitPage></PleaseWaitPage>;
  const { data, isLoading, isError, isSuccess } = useStoryLineList(path);
  const { story, storyLines } = data || {};

  const notOwner = !(story?.isOwner);

  const { mutate: deleteStoryLine } = useDeleteStoryLine();

  let mainLine: IStoryLine | undefined;
  if (isSuccess && storyLines &&  story) {
    for (const storyLine of storyLines || []) {
      if (storyLine.url.href === story.url.href) {
        mainLine = storyLine;
        break;
      }
    }
  }
  const mainLineViewUrl = mainLine?.editPath.replace("/e/", "/v/");

  const toolbar = notOwner ? null : <>
    <NewLineModal positive story={story}>
      <Icon name="plus" />
      Nowa linia fabularna
    </NewLineModal>
    <Button
      as={Link} to={story.editPath.replace("/e/", "/o/") + "autorzy/"}
      icon="user friends" content="Zarządzaj autorami" primary
    />
  </>;

  return <Layout>
    <PageHeader title={story?.title} text="Linie fabularne">{ mainLine ? null : toolbar }</PageHeader>
    {mainLine ? <>
      <StyledList horizontal>
        <List.Item as={SpacedListItem}>
          <Card className="main-line">
            <div className="main-line-cover">
              <StorySegment story={story!} to={mainLineViewUrl}></StorySegment>
            </div>
            <Card.Content>
              { mainLine.author ? <AuthorIcon author={mainLine.author}></AuthorIcon> : null }
              <StyledCardHeader>Linia główna</StyledCardHeader>
              { mainLine.author ? <Card.Meta>{ mainLine.author }</Card.Meta> : null}
            </Card.Content>
            <Card.Content extra>
              <div className='ui compact centered text labeled icon menu tiny'>
                <a href={mainLineViewUrl} className='item'><Icon name='eye' /> Podgląd</a>
                <MenuItem href={mainLine.editPath} icon='edit'>Edytuj</MenuItem>
                <MenuItem href={mainLine.mapPath} icon='sitemap'>Mapa</MenuItem>
              </div>
            </Card.Content>
          </Card>
        </List.Item>
        <div className="main-line-toolbar">{ toolbar }</div>
      </StyledList>
      <Divider />
    </> : null}
    <StyledList horizontal>
      {isLoading ? <PleaseWaitComponent /> : null}
      {isError ? (
        <div>
          Nie udało się pobrać listy linii fabularnych.
        </div>
      ) : null}
      {isSuccess && storyLines
        ? storyLines.map((storyLine: IStoryLine, idx: number) => storyLine === mainLine ? null : (
            <List.Item as={SpacedListItem} key={storyLine.url.href}>
              <Card>
                <Card.Content>
                  { storyLine.author ? <AuthorIcon author={storyLine.author}></AuthorIcon> : null }
                  <StyledCardHeader title={storyLine.title}>{storyLine.title}</StyledCardHeader>
                  <Card.Meta>{storyLine.author || <i>(brak autora)</i>}</Card.Meta>
                </Card.Content>
                <CollectionImage collection={storyLine} width={290} height={90} />
                <Card.Content extra>
                  <div className='ui compact centered text labeled icon menu tiny'>
                    <a href={storyLine.editPath.replace("/e/", "/v/")} className='item'>
                      <Icon name='eye' /> Podgląd</a>
                    <MenuItem href={storyLine.editPath} icon='edit'>Edytuj</MenuItem>
                    <MenuItem href={storyLine.mapPath} icon='sitemap'>Mapa</MenuItem>
                  </div>
                </Card.Content>
                {notOwner ? null : <Card.Content>
                  <ButtonGroup fluid>
                    {/* <Button basic positive>Dodaj autora</Button> */}
                    <ConfirmationButton basic negative
                      confirmationHeader="Potwierdź usunięcie"
                      confirmationText={<>
                        Czy na pewno chcesz <b>bezpowrotnie</b> usunąć linię fabularną?
                      </>}
                      confirmButton="Usuń"
                      onConfirm={() => deleteStoryLine({storyLine})}>Usuń linię</ConfirmationButton>
                  </ButtonGroup>
                </Card.Content>}
              </Card>
            </List.Item>
          ))
        : null}
    </StyledList>
  </Layout>;
};

function MenuItem(props: { href: string, icon: SemanticICONS, children: any }) {
  return <Link className='item' to={props.href}>
    <Icon name={props.icon} />
    {props.children}
  </Link>;
}

const StyledCardHeader = styled(Card.Header)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledList = styled(List)`
  display: flex !important; // Semantic UI override
  justify-content: flex-start;
  flex-wrap: wrap;

  .ui.image.avatar {    
    float: right;
    margin-left: 1em;
  }
`;

const SpacedListItem = styled.div`
  margin-bottom: 10px;

  .main-line {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr auto;
    width: auto;
    margin-bottom: calc(2px - 1em);
  }

  .main-line-cover {
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 8px;
  }

  & + .main-line-toolbar {
    align-self: flex-end;
    text-align: right;
    flex: 1;
  }
`;

export default StoryLinesList;
